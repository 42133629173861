import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as ChartToolTip,
  TooltipProps as RechartsTooltipProps,
  BarProps,
} from 'recharts';
import { useRecoilValue } from 'recoil';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { Box, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SummaryBox, StyledLegend, StyledSelect, Title } from './style';
import { ColorSquare } from '@/components/Analysis/DeckPlan/ColorLegend/ColorLegend';
import { unitSystem } from '@/components/Analysis/state';
import { localiseAreaMeasurement } from '@/utils/unitSystem';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { titleCase } from '@/utils';
import { abyssColors } from '@/theme/colors';

interface ChartDataItem {
  [key: string]: number | string;
}

type RecordType = { [key: string]: string | number };

type Props = {
  title: string;
  data: RecordType[];
  legendData: { [key: string]: string };
  trimLegend?: boolean;
  showRecordsLimit?: boolean;
  containerWidth: number;
  customLabel?: ReactNode;
  removeZeroValueRecords?: boolean;
  hideLegend?: boolean;
  showBarOutline?: boolean;
  barSize?: number;
};

const StyledLegendContainer = ({ label, color }: { label: string; color: string }) => (
  <Box style={{ display: 'flex', alignItems: 'center' }}>
    <StyledLegend bgcolor={color} />
    <Box style={{ fontSize: '1.1rem', fontWeight: 500 }}>{label}</Box>
  </Box>
);

export const renderLegend = (legendData: { [key: string]: string }, trimLegend: boolean) => {
  const legend = trimLegend ? Object.entries(legendData).slice(0, 3) : Object.entries(legendData);
  const remainingLegend = trimLegend
    ? Object.entries(legendData)
        .slice(3)
        .map(([label, color]) => ({
          label,
          color,
        }))
    : [];

  return (
    <Tooltip
      title={
        trimLegend ? (
          <Stack direction="row" spacing={1.5} justifyContent="flex-start">
            {remainingLegend.map(({ label, color }) => (
              <StyledLegendContainer key={label} label={label} color={color} />
            ))}
          </Stack>
        ) : (
          ''
        )
      }
    >
      <Stack
        sx={{ ...(trimLegend && { cursor: 'pointer' }), ml: 7.2 }}
        direction="row"
        spacing={1.5}
        justifyContent="flex-start"
      >
        {legend.map(([label, color]) => (
          <StyledLegendContainer key={label} label={titleCase(label)} color={color} />
        ))}
        {trimLegend && remainingLegend.length > 0 && (
          <Box style={{ fontSize: '1.1rem', fontWeight: 500 }}>...</Box>
        )}
      </Stack>
    </Tooltip>
  );
};

interface DataObject {
  name: string;
  [key: string]: number | string;
}

interface CustomTooltipProps extends RechartsTooltipProps<ValueType, NameType> {
  customLabel: ReactNode;
  selectedUnitSystem: string;
  legendData?: { [key: string]: string };
  showUnit?: boolean;
}

export const GetBarRenderer = (outline: boolean) => {
  return (props: BarProps) => {
    const { fill, x, y, width, height } = props;
    const offsetY = y ? Number(y) - 2 : y;
    if (Number(height) < 1) {
      return <rect />;
    }
    return (
      <rect
        x={x}
        y={offsetY}
        width={width}
        height={(height ?? 0) + 2}
        fill={fill}
        stroke={outline ? 'black' : 'none'}
        strokeOpacity={0.2}
        rx={2}
      />
    );
  };
};

export const CustomTooltip = ({
  active,
  payload,
  customLabel,
  legendData,
  selectedUnitSystem,
  showUnit = true,
}: CustomTooltipProps) => {
  if (active) {
    const getMatchingLegendKey = (itemName: string | undefined) => {
      if (!itemName) return '';

      if (!legendData) return titleCase(itemName);

      const matchedKey = Object.keys(legendData).find((key) =>
        key.toLowerCase().includes(itemName.toLowerCase())
      );

      return titleCase(matchedKey ?? itemName);
    };

    return (
      <Stack sx={{ backgroundColor: '#fff', p: 2, borderRadius: 1 }}>
        {payload?.reverse()?.map((item) => (
          <Box sx={{ display: 'flex' }} key={item.name}>
            <ColorSquare color={item.color ?? abyssColors.neutral[500]} />
            <Typography fontSize="1rem" sx={{ mr: 2 }}>
              {`${getMatchingLegendKey(item.name?.toString())}:`}
            </Typography>
            <Typography fontSize="1rem" sx={{ mr: 2 }}>
              {item.payload[item?.name ?? '']}
            </Typography>
            {customLabel && showUnit && (
              <Typography fontSize="1rem" color={abyssColors.primary[300]}>
                {selectedUnitSystem === UnitSystemEnum.Imperial ? '(ft²)' : '(m²)'}
              </Typography>
            )}
          </Box>
        ))}
      </Stack>
    );
  }

  return <></>;
};

const defaultRecordsLimit = 15;

export const GenericBarChartBase = ({
  title,
  data,
  legendData,
  showRecordsLimit = false,
  trimLegend = false,
  containerWidth,
  customLabel,
  removeZeroValueRecords = false,
  hideLegend = false,
  showBarOutline = false,
  barSize,
}: Props) => {
  const [limitedRecords, setLimitedRecords] = useState(data);
  const [selectedValue, setSelectedValue] = useState<number>(defaultRecordsLimit);
  const selectedUnitSystem = useRecoilValue(unitSystem);
  const graphWidth = useMemo(() => {
    const largestGraphLabelLength = data.reduce(
      (max, item) => Math.max(max, item.name.toString().length * 7),
      0
    );

    const graphContainerWidth = (showRecordsLimit ? 0.7 : 0.3) * containerWidth - 12;
    return Math.max(selectedValue * (largestGraphLabelLength + 16), graphContainerWidth);
  }, [data, containerWidth, selectedValue, showRecordsLimit]);

  const removeZeroValuesFromArray = useCallback((chartData: RecordType[]): RecordType[] => {
    const result: RecordType[] = [];
    chartData.forEach((record) => {
      const resultObject: RecordType = {};
      Object.keys(record).forEach((key) => {
        if (Object.hasOwn(record, key) && record[key] !== 0) {
          resultObject[key] = record[key];
        }
      });
      result.push(resultObject);
    });
    return result;
  }, []);

  const dataLength = useMemo(
    () => (removeZeroValueRecords ? removeZeroValuesFromArray(data).length : data.length),
    [data, removeZeroValueRecords, removeZeroValuesFromArray]
  );

  useEffect(() => {
    if (dataLength && dataLength < defaultRecordsLimit) setSelectedValue(dataLength);
  }, [data, dataLength, removeZeroValueRecords, removeZeroValuesFromArray]);

  const calculateSum = (item: ChartDataItem) => {
    return Object.values(item)
      .filter((value): value is number => typeof value === 'number')
      .reduce((accumulator, current) => accumulator + current, 0);
  };

  const sortChartsData = useCallback((chartData: ChartDataItem[]) => {
    return chartData.sort((a, b) => calculateSum(b) - calculateSum(a));
  }, []);

  const convertData = useCallback(
    (records: ChartDataItem[]): DataObject[] => {
      return records.map((record) =>
        Object.keys(record).reduce((accumulator: DataObject, key: string) => {
          if (key !== 'name' && typeof record[key] === 'number') {
            accumulator[key] =
              localiseAreaMeasurement(selectedUnitSystem, Number(record[key]), false) ?? 0;
          } else {
            accumulator[key] = record[key];
          }
          return accumulator;
        }, {} as DataObject)
      );
    },
    [selectedUnitSystem]
  );

  useEffect(() => {
    const filteredData = removeZeroValueRecords ? removeZeroValuesFromArray(data) : data;
    const sortedData = sortChartsData(filteredData).slice(0, selectedValue);
    const finalData = customLabel ? convertData(sortedData) : sortedData;

    setLimitedRecords(finalData);
  }, [
    data,
    selectedValue,
    selectedUnitSystem,
    customLabel,
    removeZeroValueRecords,
    removeZeroValuesFromArray,
    sortChartsData,
    convertData,
  ]);

  const barChartData = useMemo(() => {
    const transformedLegend: { [key: string]: string } = {};

    if (data.length === 0) return legendData;

    //all unique keys from data except for name
    let allDataKeys = new Set<string>();
    data.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== 'name') allDataKeys.add(key);
      });
    });

    // reverse the order to match the order of the legend
    allDataKeys = new Set([...allDataKeys].reverse());

    // match legend keys with collected data keys
    allDataKeys.forEach((dataKey) => {
      Object.keys(legendData).forEach((legendKey) => {
        if (legendKey.toLowerCase() === dataKey.toLowerCase()) {
          transformedLegend[dataKey] = legendData[legendKey];
        }
      });
    });

    return transformedLegend;
  }, [data, legendData]);

  return (
    <SummaryBox width={showRecordsLimit ? `${graphWidth}px` : 'auto'}>
      <Stack
        sx={{ margin: '0px 22px 0px 22px' }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box component="h4" sx={{ m: 0, height: '30px' }}>
          <Title>{title}</Title>
        </Box>
        {showRecordsLimit && selectedValue >= defaultRecordsLimit && (
          <StyledSelect
            size="small"
            variant="outlined"
            value={selectedValue}
            onChange={(event) => setSelectedValue(Number(event.target.value))}
          >
            <MenuItem value={15}>Top 15</MenuItem>
            <MenuItem value={25}>Top 25</MenuItem>
            <MenuItem value={dataLength}>All</MenuItem>
          </StyledSelect>
        )}
      </Stack>
      <ResponsiveContainer id="bar-chart-container">
        <BarChart
          margin={{ left: 0, right: 20, top: 30, bottom: -10 }}
          data={limitedRecords}
          barSize={barSize ?? 10}
          reverseStackOrder
        >
          <CartesianGrid vertical={false} color={abyssColors.primary[200]} opacity={0.2} />
          <XAxis
            domain={['auto', 'auto']}
            allowDataOverflow
            interval={0}
            tick={{ fontSize: '1rem', color: abyssColors.primary[400] }}
            tickLine={false}
            dataKey="name"
            axisLine={false}
            opacity={0.8}
          />
          <YAxis
            tick={{ fontSize: '1rem', color: abyssColors.primary[400] }}
            opacity={0.8}
            tickLine={false}
            axisLine={false}
            tickCount={6}
            tickFormatter={(value) => {
              return new Intl.NumberFormat('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              }).format(value);
            }}
          >
            {customLabel}
          </YAxis>
          <ChartToolTip
            content={
              <CustomTooltip
                customLabel={customLabel}
                selectedUnitSystem={selectedUnitSystem}
                legendData={legendData}
              />
            }
          />
          {hideLegend || (
            <Legend align="right" content={() => renderLegend(legendData, trimLegend)} />
          )}

          {Object.entries(barChartData).map(([key, color]) => (
            <Bar
              key={key}
              dataKey={key}
              fill={color}
              stackId={title}
              shape={GetBarRenderer(showBarOutline)}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </SummaryBox>
  );
};

export const GenericBarChart = memo(GenericBarChartBase);