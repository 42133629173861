import { Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { abyssColors } from '@/theme/colors';
import { Clear, Check } from '@mui/icons-material';
import { SmallButton } from '@/components/UserManagement/styles';

export const AccordionEditButton = ({
  onEdit,
  isEditing,
  onSave,
  onCancel,
  isDisabled,
}: {
  onEdit: () => void;
  isEditing: boolean;
  onSave: () => void;
  onCancel: () => void;
  isDisabled?: boolean;
}) => {
  return isEditing ? (
    <Stack direction="row" spacing={1}>
      <SmallButton
        sx={{ textTransform: 'none' }}
        startIcon={<Clear />}
        onClick={(event) => {
          event.stopPropagation();
          onCancel();
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '12px',
          }}
        >
          Cancel
        </Typography>
      </SmallButton>
      <SmallButton
        sx={{
          textTransform: 'none',
          background: abyssColors.primary[500],
          color: abyssColors.primary[100],
          padding: '0 20px 0 10px',
          '&:hover': {
            background: abyssColors.primary[500],
          },
        }}
        startIcon={<Check />}
        onClick={(event) => {
          event.stopPropagation();
          onSave();
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '12px',
          }}
        >
          Save
        </Typography>
      </SmallButton>
    </Stack>
  ) : (
    <SmallButton
      startIcon={<EditIcon />}
      onClick={(event) => {
        event.stopPropagation();
        onEdit();
      }}
      sx={{ textTransform: 'none', border: 'none' }}
      disabled={isDisabled}
      data-testid="edit-button-accordion"
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '12px',
        }}
      >
        Edit
      </Typography>
    </SmallButton>
  );
};
