import { Box, Stack } from '@mui/material';

import { Accordion } from '@/components/shared/Accordion';
import { GenericBarChart } from '@/components/shared/GenericBarChart';
import { scrollDesign } from '@/components/Analysis/Insights/InsightsExplorer/styles';

import { abyssColors } from '@/theme/colors';

type Props = {
  summedMetrics: Record<string, string | number>[];
  metricsByPlatform: Record<string, string | number>[];
  legendsData: Record<string, string>;
};

export const PlatformOverview = ({ summedMetrics, metricsByPlatform, legendsData }: Props) => {
  return (
    <Accordion
      title="Overview"
      isEditing={false}
      sx={{ backgroundColor: abyssColors.primary[50], pb: 1 }}
      data-testid="platform-overview-accordion"
    >
      <Stack direction="row" spacing={2} px={2}>
        <Box
          sx={{
            width: '30%',
            ...scrollDesign,
          }}
        >
          <GenericBarChart
            title="Alarm Level 3s by Criticality"
            data={summedMetrics}
            legendData={legendsData}
            hideLegend
            containerWidth={10}
            removeZeroValueRecords
            barSize={30}
          />
        </Box>
        <Box sx={{ width: '70%', ...scrollDesign }}>
          <GenericBarChart
            title="Alarm Level 3s by platform"
            data={metricsByPlatform}
            legendData={legendsData}
            containerWidth={10}
            removeZeroValueRecords
            barSize={30}
          />
        </Box>
      </Stack>
    </Accordion>
  );
};
