import { SmallButton } from '@/components/UserManagement/styles';
import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/material';

export const AccordionRemoveButton = ({ onRemove }: { onRemove: () => void }) => {
  return (
    <Stack direction="row" spacing={1}>
      <SmallButton
        onClick={onRemove}
        startIcon={<Delete />}
        sx={{ textTransform: 'none' }}
        data-testid="accordion-remove-button"
      >
        Remove
      </SmallButton>
    </Stack>
  );
};
