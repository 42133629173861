import { ReactNode, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  SxProps,
} from '@mui/material';
import { abyssColors } from '@/theme/colors';
import { AccordionEditButton } from './AccordionEditButton';
import { AccordionRemoveButton } from './AccordionRemoveButton';

export const Accordion = ({
  title,
  onEditClick,
  isEditing = false,
  onSaveClick,
  onCancelClick,
  onRemoveClick,
  showRemoveButton,
  isDisabled,
  subTitle,
  sx,
  children,
}: {
  title: string;
  onEditClick?: () => void;
  isEditing?: boolean;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  onRemoveClick?: () => void;
  showRemoveButton?: boolean;
  isDisabled?: boolean;
  subTitle?: string;
  sx?: SxProps;
  children: ReactNode;
}) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (isEditing || showRemoveButton) {
      setExpanded(true);
    }
  }, [isEditing, showRemoveButton, expanded]);

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        ...sx,
        boxShadow: 'none',
        border: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: abyssColors.primary[900] }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" spacing={1}>
            <Typography color={abyssColors.primary[500]} fontWeight={500} fontSize={16}>
              {title}
            </Typography>
            <Typography color={abyssColors.primary[300]} fontWeight={500} fontSize={14}>
              {subTitle}
            </Typography>
          </Stack>
          {onEditClick && (
            <AccordionEditButton
              isEditing={isEditing}
              onEdit={onEditClick}
              onSave={() => onSaveClick && onSaveClick()}
              onCancel={() => onCancelClick && onCancelClick()}
              isDisabled={isDisabled}
            />
          )}
          {showRemoveButton && onRemoveClick && <AccordionRemoveButton onRemove={onRemoveClick} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
