import React, { useMemo } from 'react';
import { Typography, Stack, Tooltip, Box, Divider } from '@mui/material';
import { PlatformCardContainer, PlatformImg } from '../styles';
import { FALLBACK_INSPECTION_IMAGE } from '@/constants';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { abyssColors } from '@/theme/colors';
import { Maybe, PlatformCardInsights, UnitSystemEnum } from '@/__generated__/graphql';
import { convertMetresSqToFeetSqDisplay } from '@/utils/unitSystem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as analysisState from '@/components/Analysis/state';
import * as state from '@/state/atoms';
import { formatNumberForDisplay } from '@/utils';

type PlatformDisplay = 'coating' | 'health' | 'criticality';

type PlatformCardType = {
  name: string;
  imageUrl?: string;
  subHeading: string;
  children?: React.ReactNode;
  platformCardInsights: Maybe<Array<PlatformCardInsights>> | undefined;
};

type CardDataProps = {
  name: string;
  value: string;
  color: string;
  divider?: boolean;
};

const calculateArea = (area: string, unitSystem: UnitSystemEnum) => {
  const value = Number(area);
  return unitSystem === UnitSystemEnum.Imperial
    ? Number(convertMetresSqToFeetSqDisplay(Number(value), false)).toFixed(0)
    : Number.isInteger(value)
      ? value
      : value.toFixed(0);
};

const CardData = ({ color, name: scanName, value, divider }: CardDataProps) => {
  return (
    <>
      {divider && <Divider sx={{ width: '100%', marginTop: 2 }} />}
      <Stack direction="row" alignItems="center" mt={2} justifyContent="space-between" width="100%">
        <span
          style={{
            backgroundColor: color,
            borderRadius: 2,
            width: 10,
            height: 10,
          }}
        />

        <Typography fontSize={12} color={abyssColors.primary[400]} pl={0.5} noWrap>
          {scanName}
        </Typography>

        <Box
          sx={{
            border: `1px solid ${abyssColors.primary[200]}`,
            mx: 1.5,
            flexGrow: 1,
          }}
        />

        <Typography fontSize={12} color={abyssColors.primary[400]} textAlign="right">
          {value}
        </Typography>
      </Stack>
    </>
  );
};

export const PlatformCard = ({
  name,
  imageUrl,
  subHeading,
  children,
  platformCardInsights,
}: PlatformCardType) => {
  const unitSystem = useRecoilValue(analysisState.unitSystem);

  const setPlatformsFilters = useSetRecoilState(state.platformsFilters);
  const platformSelectedFilter = useRecoilValue(state.platformSelectedFilter);

  const cardInsightsMapped = useMemo(() => {
    if (platformCardInsights && platformCardInsights.length > 0) {
      const mappedData = platformCardInsights?.reduce(
        (accumulator, current) => {
          if (!accumulator[current.group.toLowerCase()]) {
            accumulator[current.group.toLowerCase()] = [];
          }
          accumulator[current.group.toLowerCase()].push(current);
          return accumulator;
        },
        {} as Record<string, PlatformCardInsights[]>
      );
      setPlatformsFilters((previousFilters) => {
        const newFilters = Object.keys(mappedData);
        const allFilters = new Set([...previousFilters, ...newFilters]);
        return [...allFilters] as PlatformDisplay[];
      });

      return mappedData;
    }
    return {};
  }, [platformCardInsights, setPlatformsFilters]);

  const cardInsights = cardInsightsMapped[platformSelectedFilter];

  const ColorBar = useMemo(() => {
    if (cardInsights && cardInsights?.length > 0) {
      const total = cardInsights?.reduce((accumulator, item) => {
        return accumulator + Number(item.value);
      }, 0);
      const sections = cardInsights
        .toSorted((a, b) => (a?.displayConfig?.barIndex || 0) - (b?.displayConfig?.barIndex || 0))
        .map((item) => ({
          percentage: (Number(item.value) / total) * 100,
          color: item?.displayConfig?.color,
          name: item.name,
        }));
      return (
        <Box display="flex" height="24px" width="100%">
          {sections.map((section, index) => (
            <Box
              key={`${section.name}-${index}`}
              borderRadius="1px"
              marginRight={index < sections.length - 1 ? '4px' : 0}
              width={`${section.percentage}%`}
              bgcolor={section.color ?? '#fff'}
            />
          ))}
        </Box>
      );
    }
  }, [cardInsights]);

  return (
    <PlatformCardContainer>
      <PlatformImg>
        <img
          style={{
            width: '100%',
            aspectRatio: '79/37',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          src={(imageUrl && getCloudfrontUrl(imageUrl)) || FALLBACK_INSPECTION_IMAGE}
          alt={name}
          onError={(event) => {
            const reference = event.currentTarget;
            reference.src = FALLBACK_INSPECTION_IMAGE;
          }}
          crossOrigin="use-credentials"
        />
      </PlatformImg>
      <Stack>
        <Stack alignItems="flex-start">
          <Tooltip title={<Typography fontSize="1.4rem">{name}</Typography>}>
            <Typography
              fontSize="2rem"
              color="text.primary"
              fontWeight={500}
              lineHeight={1.3}
              marginBottom={0.5}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: 'left',
              }}
            >
              {name}
            </Typography>
          </Tooltip>

          {cardInsightsMapped && (
            <>
              {cardInsights?.length > 0 && (
                <>
                  {ColorBar}
                  {cardInsights
                    ?.toSorted(
                      (a, b) =>
                        (a?.displayConfig?.listIndex || 0) - (b?.displayConfig?.listIndex || 0)
                    )
                    ?.map((item) => (
                      <CardData
                        key={item.name}
                        name={item.name}
                        color={item?.displayConfig?.color || '#fff'}
                        value={
                          platformSelectedFilter === 'coating'
                            ? `${formatNumberForDisplay(calculateArea(item.value, unitSystem))} ${unitSystem === UnitSystemEnum.Imperial ? 'ft²' : 'm²'}`
                            : formatNumberForDisplay(item.value)
                        }
                        divider={Boolean(item?.displayConfig?.divider)}
                      />
                    ))}
                </>
              )}
            </>
          )}
          {children || <></>}
        </Stack>
        <Typography
          variant="body2"
          fontSize="1.2rem"
          color="text.primary"
          mt={1.2}
          textAlign="left"
        >
          {subHeading}
        </Typography>
      </Stack>
    </PlatformCardContainer>
  );
};
