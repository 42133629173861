import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useDefectLegendsDeckSummaryQuery, DefectLegend } from '@/__generated__/graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import { Box, Stack, styled as muiStyled } from '@mui/material';

export const ColorSquare = muiStyled(Box)<{ color: string }>(({ color }) => ({
  height: '1rem',
  width: '1rem',
  borderRadius: '0.2rem',
  backgroundColor: color,
}));

export const ColorLegend = ({ sxProps = {} }: { sxProps?: object }) => {
  const [defects, setDefects] = useState<Array<DefectLegend> | null>();
  const heatmapValue = useRecoilValue(state.appliedHeatmapValue);
  const structureId = useGetStructureId();

  const { loading, data } = useDefectLegendsDeckSummaryQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!loading && data) {
      setDefects(data.structure?.defectLegends.filter((item) => item.groupKey === heatmapValue));
    }
  }, [data, loading, setDefects, heatmapValue]);

  return (
    <Stack
      data-testid="color-legend-container"
      direction="row"
      gap="1.6rem"
      sx={{
        mt: 1,
        ...sxProps,
      }}
    >
      {defects?.map((item) => (
        <Stack direction="row" alignItems="baseline" gap="0.4rem" key={item.name}>
          <ColorSquare color={item.color} />
          <Typography variant="caption">{item.name}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
