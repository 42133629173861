import styled from 'styled-components';
import { Box, Select, Typography } from '@mui/material';
import { abyssColors } from '../../../theme/colors';

interface SummaryBoxProps {
  width?: string;
}

export const SummaryBox = styled(Box)<SummaryBoxProps>(({ width }) => ({
  paddingTop: '10px',
  paddingBottom: '44px',
  height: '254px',
  border: '1px solid #e7eef6',
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  width,
}));

export const StyledLegend = styled(Box)({
  borderRadius: '2px',
  width: '10px',
  height: '10px',
  marginRight: '6px',
});

export const StyledSelect = styled(Select)({
  height: '30px',
  padding: 0,
  border: '1px solid #B8CADD',
  borderRadius: '5px',
  textTransform: 'capitalize',
  color: '#50739B',
});

export const Title = styled(Typography)({
  fontSize: '1.4rem !important',
  fontWeight: '500 !important',
  color: abyssColors.primary[500],
});
